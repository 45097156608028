import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ICredentials, ILoginResponse } from 'src/app/shared/interfaces/credentials/credentials';
import { IUser } from 'src/app/shared/interfaces/user/user';

const AUTH_ENDPOINT = 'auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user$ = new BehaviorSubject<IUser | undefined>(undefined);

  getUserLogged$(): Observable<IUser | undefined> {
    return this.user$.asObservable();
  }

  setUserLogged$(user: IUser | undefined) {
    this.user$.next(user);
  }

  constructor(private httpClient: HttpClient) { }

  login(credentials: ICredentials) {
    return this.httpClient.post(`${environment.claroApiUrl}${AUTH_ENDPOINT}/${environment.actions.login}`, credentials).pipe(
      map((res) => {
        let response = res as ILoginResponse;
        localStorage.setItem('access_token', response.access_token);
        return response;
      })
    );
  }

  getAccesToken() {
    return localStorage.getItem('access_token');
  }

  getUserLoggedData() {
    return this.httpClient.post(`${environment.claroApiUrl}${AUTH_ENDPOINT}/${environment.actions.me}`, {});
  }

}
